export default {
    api: {
        generic: "https://ecomm.api.vyayatatech.com/public",
        // generic: "http://localhost:8000/public",
        image: "https://fileoperations-dot-inhouse-all-services-420412.el.r.appspot.com",
        googlesheet: "https://inhouse-generic-services.el.r.appspot.com"
    },
    assets: {
        products:"https://storage.googleapis.com/inhouse-ecommerce-sas-public",
        generic:"assets/generic"
    },
    secretKey: "123456789",
    pagination: {
        limitPerPage: 10
    }
}