// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowTrendUp, faEdit } from '@fortawesome/free-solid-svg-icons';
import * as bootstrap from 'bootstrap';
// components 
import ConfirmationBox from '../../components/confirmation-box';
import PublicUrlModal from '../../components/public-url-modal'
// config & functions 
import config from '../../config';

function Table({loadapidata, offset, list, orgInfo, token}) {
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [ShowPublicUrlModal, setShowPublicUrlModal] = useState(false);
    const [DeleteRowId, setDeleteRowId] = useState(0);
    const [EditId, setEditRowId] = useState(0);
    const deleteUrl = `${config.api.generic}/public-url-products`;

    const handleDelete = (rowId) => {
        setDeleteRowId(rowId);
        setShowDeleteModalBox(true);
    }

    const handleEdit = (rowId) => {
        setEditRowId(rowId);
        setShowPublicUrlModal(true);
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-hover fs-14rem">
                    <thead>
                        <tr>
                            <th>Unqiue Url</th>
                            <th>Reference Name</th>
                            <th>Status</th>
                            <th>Permission</th>
                            <th>Expires On</th>
                            <th>Public Url Ids</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data => {
                            return <tr key={Math.random()}>
                                <td>{data.unique_url}</td>
                                <td>{data.reference_name}</td>
                                <td>{Number(data.status) ? 'Active': 'Inactive'}</td>
                                <td>{Number(data.permission) ? 'Write': 'Read'}</td>
                                <td>{data.expires_on}</td>
                                <td>{data.public_url_ids}</td>
                                <td>
                                    <div style={{width: '60px'}} className="d-flex justify-content-between">
                                        <button onClick={() => handleDelete(data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                        <button onClick={() => handleEdit(data.id)}  className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faEdit} className="dashboard-card-icon" /></button>
                                        <a href={`https://ecomm.pcp.vyayatatech.com/${orgInfo.domain}/${data.unique_url}`} target='_blank'><FontAwesomeIcon icon={faArrowTrendUp} className="dashboard-card-icon text-default" /></a>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{id:DeleteRowId}} token={token} /> : null}
            {(ShowPublicUrlModal) ? <PublicUrlModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowpublicurlmodal={setShowPublicUrlModal} params={{id:EditId}} token={token} /> : null}
        </>
    } else {
        return null;
    }
}

export default Table;