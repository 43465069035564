// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components 
import Footer from "../../../components/footer";
import "../../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// config & functions 
import ajaxCall from '../../../utils/ajaxcall';
import Config from "../../../config/index";

function FormModal({loadapidata, apidataparams, setshowformmodalbox, productid, variantid, token, orgInfo}) {

    const [InputFields, setInputFields] = useState({
        productId: "",
        variantId: "",
        productName: "",
        color: "",
        skuName: "",
        mrp: "",
        discount: "",
        sizeId: "",
        inStock: "",
        totalSold: "",
        totalDamage: "",
        statusId: "",
        productUrls: ""
    });

    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState((parseInt(variantid)) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: true, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [SizeList, setSizeList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        let postData = {
            productId: productid,
            variantId: variantid,
            skuName: data.skuName,
            mrp: data.mrp,
            discount: data.discount,
            sizeId: data.sizeId,
            inStock: data.inStock,
            totalSold: data.totalSold,
            totalDamage: data.totalDamage,
            statusId: data.statusId,
            productUrls: data.productUrls
        }
        let apiCall = "";
        if(parseInt(variantid)) {
            apiCall = await ajaxCall.patchCall(`${Config.api.generic}/products/variant`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${Config.api.generic}/products/variant`, postData, token);
        }
        
        if(apiCall) {
            setSubmitButton((variantid)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton((variantid)?'UPDATE RECORD':'SAVE RECORD');
            toast.error('Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }

    const fetchSizeList = async () => {
        let apiCall = await ajaxCall.getCall(`${Config.api.generic}/size`, {}, token);
        if(apiCall) {
            setSizeList(apiCall.data);
        }
    }
    
    const fetchStatusList = async () => {
        let apiCall = await ajaxCall.getCall(`${Config.api.generic}/products/status`, {}, token);
        if(apiCall) {
            setStatusList(apiCall.data);
        }
    }

    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (parseInt(variantid)) {
                // fetch record detials to edit
                let apiCall = await ajaxCall.getCall(`${Config.api.generic}/products/variant/row`, {
                    productId: (productid) ? productid : 0, 
                    variantId: (variantid) ? variantid : 0
                }, token);
                if(apiCall) {
                    let onLoadData = {
                        productId: productid,
                        variantId: variantid,
                        productName: apiCall.data[0].product_name,
                        color: apiCall.data[0].color_text,
                        skuName: apiCall.data[0].sku_name,
                        mrp: apiCall.data[0].mrp,
                        discount: apiCall.data[0].discount,
                        sizeId: apiCall.data[0].size_id,
                        inStock: apiCall.data[0].in_stock,
                        totalSold: apiCall.data[0].total_sold,
                        totalDamage: apiCall.data[0].total_damage,
                        statusId: apiCall.data[0].product_status_id,
                        productUrls: apiCall.data[0].product_urls
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            } else {
                // fetch product name and color to display in form
                let apiCall = await ajaxCall.getCall(`${Config.api.generic}/products/variant-info`, {
                    productId: (productid) ? productid : 0
                }, token);
                if(apiCall) {
                    let onLoadData = {
                        productId: productid,
                        variantId: variantid,
                        productName: apiCall.data[0].name,
                        color: apiCall.data[0].color_text,
                        skuName: '',
                        mrp: '',
                        discount: '',
                        sizeId: '',
                        inStock: '',
                        totalSold: '',
                        totalDamage: '',
                        statusId: '',
                        productUrls: ''
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                }
            }
        }
        fetchData();
        fetchSizeList();
        fetchStatusList();
        loadComponent();
    }, []);

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">{variantid?'Update Variant':'Add Variant'}</h5>
							<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
						</div>
						<div className="modal-body">
                            <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    {(Notification.status) ?
                                        <div className="col-12 text-center">{Notification.message}</div> : <>
                                            <div className="col-md-12">
                                                <div className="text-left mt-3 vt-txt-dark-blue">
                                                    <h3><b>Product Variant</b></h3>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="form-label fs-14rem">Name</label>
                                                <div className="fs-14rem fw-bold">{`${InputFields.productName} - ${InputFields.color}`}</div>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Sku Name</label>
                                                <input type="text" defaultValue={InputFields.skuName} className="form-control" name="skuName" {...register("skuName", { required: true, minLength: 12, maxLength: 18 })} />
                                                <small className="form-text text-danger">
                                                    {errors.skuName?.type === "required" && "Sku Name is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.skuName?.type === "minLength" && "Enter atleast 12 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.skuName?.type === "maxLength" && "Only 18 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">MRP</label>
                                                <input type="text" defaultValue={InputFields.mrp} className="form-control" name="mrp" {...register("mrp", { required: true, minLength: 1, maxLength: 6 })} />
                                                <small className="form-text text-danger">
                                                    {errors.mrp?.type === "required" && "Mrp is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.mrp?.type === "minLength" && "Enter atleast 1 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.mrp?.type === "maxLength" && "Only 6 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Discount</label>
                                                <input type="text" defaultValue={InputFields.discount} className="form-control" name="discount" {...register("discount", { required: true, minLength: 1, maxLength: 2 })} />
                                                <small className="form-text text-danger">
                                                    {errors.discount?.type === "required" && "Discount is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.discount?.type === "minLength" && "Enter atleast 1 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.discount?.type === "maxLength" && "Only 2 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Size</label>
                                                <Controller
                                                    name='sizeId' 
                                                    defaultValue={InputFields.sizeId}
                                                    rules={{required: true}}
                                                    control={control} 
                                                    render={({field}) => (
                                                        <select value={field.value || ""} className="form-control custom-select" name="sizeId" onChange={(e) => {updateChangeEvent(e, field)}}>
                                                            <option value=""> Choose...</option>
                                                            {(Array.isArray(SizeList) && SizeList.length > 0) ? SizeList.map(d => {
                                                                return <option key={Math.random()} value={d.id}>{d.label}</option>
                                                            }):null}
                                                        </select>
                                                    )}
                                                />	
                                                <small className="form-text text-danger">
                                                    {errors.sizeId?.type === "required" && "Please update size"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">In Stock</label>
                                                <input type="text" defaultValue={InputFields.inStock} className="form-control" name="inStock" {...register("inStock", { required: true, minLength: 1, maxLength: 5 })} />
                                                <small className="form-text text-danger">
                                                    {errors.inStock?.type === "required" && "In Stock is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.inStock?.type === "minLength" && "Enter atleast 1 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.inStock?.type === "maxLength" && "Only 2 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Total Sold</label>
                                                <input type="text" defaultValue={InputFields.totalSold} className="form-control" name="totalSold" {...register("totalSold", { required: true, minLength: 1, maxLength: 5 })} />
                                                <small className="form-text text-danger">
                                                    {errors.totalSold?.type === "required" && "Total Sold is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.totalSold?.type === "minLength" && "Enter atleast 1 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.totalSold?.type === "maxLength" && "Only 2 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Total Damage</label>
                                                <input type="text" defaultValue={InputFields.totalDamage} className="form-control" name="totalDamage" {...register("totalDamage", { required: true, minLength: 1, maxLength: 5 })} />
                                                <small className="form-text text-danger">
                                                    {errors.totalDamage?.type === "required" && "Total Damage is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.totalDamage?.type === "minLength" && "Enter atleast 1 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.totalDamage?.type === "maxLength" && "Only 2 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <label className="form-label fs-14rem">Status</label>
                                                <Controller
                                                    name='statusId' 
                                                    defaultValue={InputFields.statusId}
                                                    rules={{required: true}}
                                                    control={control} 
                                                    render={({field}) => (
                                                        <select value={field.value || ""} className="form-control custom-select" name="statusId" onChange={(e) => {updateChangeEvent(e, field)}}>
                                                            <option value=""> Choose...</option>
                                                            {(Array.isArray(StatusList) && StatusList.length > 0) ? StatusList.map(d => {
                                                                return <option key={Math.random()} value={d.id}>{d.label}</option>
                                                            }):null}
                                                        </select>
                                                    )}
                                                />	
                                                <small className="form-text text-danger">
                                                    {errors.statusId?.type === "required" && "Please update status"}
                                                </small>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <label className="form-label fs-14rem">Product Urls Config (FK, Amazon)</label>
                                                <textarea defaultValue={InputFields.productUrls} rows="5" className="form-control" name="productUrls" {...register("productUrls", { required: false, minLength: 5, maxLength: 500 })}></textarea>
                                                <small className="form-text text-danger">
                                                    {errors.productUrls?.type === "required" && "Product Urls is mandatory"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.productUrls?.type === "minLength" && "Enter atleast 5 characters"}
                                                </small>
                                                <small className="form-text text-danger">
                                                    {errors.productUrls?.type === "maxLength" && "Only 500 characters allowed"}
                                                </small>
                                            </div>
                                            <div className="col-12 text-end">
                                                <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                            </div>
                                        </>}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default FormModal;