import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import TotalCount from "../../components/total-count";

function Card({token, title, apiPath, preFix}) {

    useEffect(() => {
        async function fetchData() {
        };
        fetchData();
    }, []);

    return <div className="card card-stats mb-4 mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">{title}</h5>
                    {/* <div className="h2 font-weight-bold mb-0">{preFix} <TotalCount token={token} apiPath={apiPath} /></div> */}
                    <div className="h2 font-weight-bold mb-0">{preFix} 0</div>
                </div>
                <div className="col-auto">
                    <div className="icon icon-shape ">
                        <FontAwesomeIcon icon={faEnvelope} className="dashboard-card-icon" />
                    </div>
                </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-success mr-2"><FontAwesomeIcon icon={faArrowUp} className="dashboard-card-arrowup" /> ~% </span>
            <span className="text-nowrap">Since last month</span>
            </p>
        </div>
    </div>
}

export default Card;