// libs
import React, {useEffect, useState} from 'react'
import Pagination from "react-js-pagination";
// components
import Navbar from "../../../components/navbar";
import FormModal from './form-modal';
import "../../../assets/css/style.css";
import Breadcrumb from "../../../components/breadcrumb";
import Loading from '../../../components/loading';
import Table from './table';
import Record404 from '../../../components/record404';
import PublicUrlModal from '../../../components/public-url-modal';
// config & functions 
import ajaxCall from '../../../utils/ajaxcall';
import config from '../../../config';
import utils from '../../../utils';

function List({token, orgInfo}) {
	let query = utils.useQuery();
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [PublicUrlIds, setPublicUrlIds] = useState([]);
	const [ShowPublicUrlModal, setShowPublicUrlModal] = useState(false);
	// get query param
	let paginationNo = query.get("page");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
	let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;

	const handlePublicUrlChange = (event) => {
		const { checked, id } = event.target;
		if (checked) {
			setPublicUrlIds([...PublicUrlIds, Number(id)]);
		} else {
			setPublicUrlIds(PublicUrlIds.filter((existingId) => Number(existingId) !== Number(id)));
		}
	};

	const loadApiData = async (offset) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/products`, {offset:offset, limit: config.pagination.limitPerPage}, token);
        if(apiData) {
            setListData(apiData.data.records);
            setTotalListCount(apiData.data.totalCount);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }
	
	const handlePageChange = async (pageNumber) => {
		localStorage.setItem("PublicUrlIds", JSON.stringify(PublicUrlIds));
		utils.redirect(`/products?page=${pageNumber}`)
	}

	const handleSelectAllPublicUrl = () => {
		let nArr = [];
		ListData.forEach(ele => {
			nArr.push(Number(ele.id))
		})
		let mArr = [...new Set([...PublicUrlIds, ...nArr])];
		localStorage.setItem("PublicUrlIds", JSON.stringify(mArr));
		setPublicUrlIds(mArr);
		console.log(mArr)
	}

	useEffect(() => {
        async function fetchData() {
			let publicUrlIds = localStorage.getItem("PublicUrlIds");
			let ids = publicUrlIds ? JSON.parse(publicUrlIds) : [];
			setPublicUrlIds(ids);
			loadApiData((offset == 1) ? 0 : offset); // offset should start from 0
        };
        fetchData();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Products List</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Products', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" onClick={() => setShowFormModalBox(true)} className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD PRODUCTS</button>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col">
						<button className="btn btn-sm border fs-14rem me-2" onClick={handleSelectAllPublicUrl}>Select All</button>
						{PublicUrlIds && Array.isArray(PublicUrlIds) && PublicUrlIds.length > 0 ? <button className="btn btn-sm border fs-14rem" onClick={() => setShowPublicUrlModal(true)}>Create Public URL ({PublicUrlIds.length})</button> : null}
					</div>
				</div>
				<div className="row">
					<div className="col">
						{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <Table handlepublicurlchange={handlePublicUrlChange} publicurlids={PublicUrlIds} loadapidata={loadApiData} offset={offset} list={ListData} orgInfo={orgInfo} token={token} />}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
			{(ShowFormModalBox) ? <FormModal loadapidata={loadApiData} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} productid={0} token={token} orginfo={orgInfo} /> : null}
			{(ShowPublicUrlModal) ? <PublicUrlModal setshowpublicurlmodal={setShowPublicUrlModal} token={token} orginfo={orgInfo} /> : null}
		</>
	)
}

export default List;