// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faTextHeight } from '@fortawesome/free-solid-svg-icons';
import * as bootstrap from 'bootstrap';
// components 
import ConfirmationBox from '../../../components/confirmation-box';
import FormModal from './form-modal';
import VariantListModal from '../variant-modal/list-modal'
import ImagePreview from '../../../components/image-preview-box';
// config & functions 
import config from '../../../config';

function Table({handlepublicurlchange, publicurlids, loadapidata, offset, list, orgInfo, token}) {
    const [ProductPID, setProductPID] = useState(0);
	const [ShowVariantModalBox, setShowVariantModalBox] = useState(false);
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const deleteUrl = `${config.api.generic}/products`;

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orgInfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const PrintImage = ({img}) => {
        if(img) {
            let sa = img.split(",");
            return <div className='bg-secondary rounded-circle d-flex justify-content-center align-items-center' style={{width: '40px', height: '40px'}} onClick={e => handleViewImage(true, sa[0])}>
                <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${sa[0]}`} className='img-fluid' style={{width: '40px', height: '40px'}} />
            </div>
        } else {
            return <div className='bg-secondary rounded-circle' style={{width: '40px', height: '40px'}}></div>
        }
    }

    const Checkbox = ({id}) => {
        let isChecked = "";
        if(publicurlids && publicurlids === 'all') {
            isChecked = true;
        } else {
            isChecked = publicurlids && publicurlids.includes(id);
        }
        return <input
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={handlepublicurlchange}
        />
    }

	const handFormModal = (status, ppid) => {
        setProductPID(ppid);
        setShowFormModalBox(status);
    }
	
    const handleVariantModal = (status, ppid) => {
        setProductPID(ppid);
        setShowVariantModalBox(status);
    }

    const handleDelete = (rowId) => {
        setRowId(rowId);
        setShowDeleteModalBox(true);
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-hover fs-14rem">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Color</th>
                            <th><div className="d-flex align-items-center">ASP <button type='button' className="bg-transparent border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Average Selling Price"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                                </svg></button></div>
                            </th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data => {
                            return <tr key={Math.random()}>
                                <td><Checkbox id={data.id} /></td>
                                <td><PrintImage img={data.image} /></td>
                                <td>{data.name}</td>
                                <td>{data.color_label}</td>
                                <td>Rs {data.average_selling_price || 0}/-</td>
                                <td>{data.product_status_label}</td>
                                <td>
                                    <div style={{width: '100px'}} className="d-flex justify-content-between">
                                        <button onClick={() => handFormModal(true, data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></button>
                                        <button onClick={() => handleDelete(data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                        <button onClick={() => handleVariantModal(true, data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTextHeight} className="dashboard-card-icon text-dark" /></button>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{id:RowId}} token={token} /> : null}
            {(ShowFormModalBox) ? <FormModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} productid={ProductPID} token={token} orginfo={orgInfo} /> : null}
            {(ShowVariantModalBox) ? <VariantListModal showvariantmodalbox={setShowVariantModalBox} productid={ProductPID} token={token} orginfo={orgInfo} /> : null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    } else {
        return null;
    }
}

export default Table;