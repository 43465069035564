import React from 'react'

function Footer() {
    return (
        <> <footer className="bg-light text-center border-top text-lg-start p-3">
            <div className="text-center vt-txt-dark-blue fs-14rem">
                &copy; 2022, Developed and Maintained by
                <a href="https://vyayatatech.com/" className="foot-link vt-txt-dark-blue text-decoration-none fw-bold"> Vyayata Technologies</a>
            </div>
            <div className="text-center vt-txt-dark-blue fs-14rem">
                For queries please email us on: 
                <a href="mailto:info@vyayatatech.com" className="foot-link vt-txt-dark-blue text-decoration-none"> info@vyayatatech.com</a>
            </div>
        </footer>
        </>
    )
}

export default Footer;
