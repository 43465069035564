// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
// components 
import ConfirmationBox from '../../../components/confirmation-box';
import FormModal from './form-modal';
// config & functions 
import config from '../../../config';

function Table({loadapidata, productid, list, token, orginfo}) {
    const [ProductPID, setProductPID] = useState(0);
    const [VariantID, setVariantID] = useState(0);
    const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const deleteUrl = `${config.api.generic}/products/variant`;

    const handleEditVariant = (status, pId, vId) => {
        setProductPID(pId);
        setVariantID(vId);
        setShowFormModalBox(status);
    }

    const handleDelete = (rowId) => {
        setRowId(rowId);
        setShowDeleteModalBox(true);
    }

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-striped fs-14rem">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th>Sku Name</th>
                            <th>MRP</th>
                            <th>Discount</th>
                            <th>In Stock</th>
                            <th>Total Sold</th>
                            <th>Total Damage</th>
                            <th>Status</th>
                            {(orginfo.roleId === 1) ? <th>Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data => {
                            return <tr key={Math.random()}>
                                <td>{data.name}</td>
                                <td>{data.color_text}</td>
                                <td>{data.size_text}</td>
                                <td>{data.sku_name}</td>
                                <td>{data.mrp}</td>
                                <td>{data.discount}%</td>
                                <td>{data.in_stock}</td>
                                <td>{data.total_sold}</td>
                                <td>{data.total_damage}</td>
                                <td>{data.status_text}</td>
                                {(orginfo.roleId === 1) ? <td>
                                    <div style={{width: '120px'}}>
                                        <button onClick={() => handleEditVariant(true, data.product_id, data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></button>
                                        <button onClick={() => handleDelete(data.id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                    </div>
                                </td> : null}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={{productId: productid}} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{id:RowId}} token={token} /> : null}
            {(ShowFormModalBox) ? <FormModal loadapidata={loadapidata} apidataparams={{productId: productid}} setshowformmodalbox={setShowFormModalBox} productid={ProductPID} variantid={VariantID} token={token} /> : null}
        </>
    } else {
        return null;
    }
}

export default Table;