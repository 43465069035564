import React from 'react'
import Navbar from "../../components/navbar";
import "../../assets/css/style.css";
import Card from "./card";

function Dashboard({token, orgInfo}) {
	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row mb-4 mt-5">
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Total Products" apiPath="products" preFix="" />
					</div>
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Total Instock" apiPath="instock" preFix="" />
					</div>
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Total Out of Stock" apiPath="outofstock" preFix="" />
					</div>
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Total Damage" apiPath="damage" preFix="" />
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Total Sales" apiPath="products-sales" preFix="" />
					</div>
					<div className="col-xl-3 col-lg-6">
						<Card token={token} title="Order Cancelled" apiPath="returns" params={{type: 'cancelled'}} preFix="" />
					</div>
				</div>
			</div>
		</>
	)
}

export default Dashboard;