export default {
    "color": "Color",
    "secondaryColor": "Secondary color",
    "outerMaterial": "Outer Material",
    "modelName": "Model Name",
    "idealFor": "Ideal For",
    "occasion": "Occasion",
    "typeFormal": "Type Formal",
    "season": "Season",
    "soleMaterial": "Sole Material",
    "weight": "Weight",
    "packOf": "Pack Of",
    "careInstructions": "Care Instructions",
    "netQuantity": "Net Quantity",
    "tipShape": "Tip shape",
    "countryOfOrigin": "Country of Origin"
}