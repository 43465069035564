// libs
import React, {useEffect, useRef, useState} from 'react'
import { Modal } from 'bootstrap';
import { ToastContainer, toast } from 'react-toastify';
// components 
// ... 
// config & functions 
import ajaxCall from '../utils/ajaxcall'

function ConfirmationBox({loadapidata, apidataparams, setshowdeletemodalbox, url, params, token}) {

    const wrapperRef = useRef(null);
    const [IsDelete, setIsDelete] = useState(false);

    const hideModal = () => {
		const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowdeletemodalbox(false); // make it 0 and remove component from parent div
	}

    const initDeleteRecord = async () => {
        setIsDelete(true);
        const apiCall = await ajaxCall.deleteCall(url, params, token);
        if(apiCall) {
            setIsDelete(false);
            toast.info('Record deleted successfully');
            loadapidata(apidataparams); // refresh list page
            setTimeout(() => {
                hideModal();
            }, 1000);
        } else {
            setIsDelete(false);
            toast.error('Something went wrong. Please try again');
        }
    }

    useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }
        loadComponent();
    });

    return (
        <div className="modal fade" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
			<div className="modal-dialog">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Confirmation Box</h5>
					<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
				</div>
				<div className="modal-body">
					Are you sure you want to delete this record?
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
					<button type="button" className="btn btn-primary fs-14rem" disabled={IsDelete} onClick={() => initDeleteRecord()}>{(IsDelete)?'Deleting...':'Delete'}</button>
				</div>
				</div>
			</div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</div>
    )
}
export default ConfirmationBox;
