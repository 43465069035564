// libs
import React, {useEffect, useState, useRef} from 'react'
import { Modal } from 'bootstrap';
// components
import "../../../assets/css/style.css";
import Loading from '../../../components/loading';
import Table from './table';
import Record404 from '../../../components/record404';
import FormModal from './form-modal';
// config & functions 
import ajaxCall from '../../../utils/ajaxcall';
import config from '../../../config';

function VariantListModal({showvariantmodalbox, productid, token, orginfo}) {

	const [ListData, setListData] = useState([]);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
	const wrapperRef = useRef(null);

	const loadApiData = async (params) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/products/variant`, params, token);
        if(apiData) {
            setListData(apiData.data);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

	const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        showvariantmodalbox(false)
	}

	useEffect(() => {
		async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
			loadApiData({productId: productid});
        };
        fetchData();
		loadComponent();
    }, []);
	
	return (
		<>
			<div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">{productid?'Update Variant':'Add Variant'}</h5>
							<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="container">
								<div className="row">
									<div className="col">
										{(IsLoading) ? <Loading /> : (Is404) ? <>
											<Record404 />
											{(orginfo.roleId === 1) ? <div className="text-center"><button type="button" className="vt-primary-btn btn-sm btn btn-outline-none text-white fs-14rem my-3" onClick={() => setShowFormModalBox(true)} >ADD VARIANT</button></div> : null}
										</> : <>
											<Table loadapidata={loadApiData} productid={productid} list={ListData} token={token} orginfo={orginfo} />
											{(orginfo.roleId === 1) ? <div className="text-center"><button type="button" className="vt-primary-btn btn-sm btn btn-outline-none text-white fs-14rem my-3" onClick={() => setShowFormModalBox(true)} >ADD VARIANT</button></div> : null}
										</>}
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
					</div>
				</div>
			</div>
			{(ShowFormModalBox) ? <FormModal loadapidata={loadApiData} apidataparams={{productId: productid}} setshowformmodalbox={setShowFormModalBox} productid={productid} variantid={0} token={token} /> : null}
		</>
	)
}

export default VariantListModal;