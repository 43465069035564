// libs
import React, {useEffect, useState} from 'react'
import Pagination from "react-js-pagination";
// components
import Navbar from "../../components/navbar";
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function List({token, orgInfo}) {
	let query = utils.useQuery();
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	// get query param
	let paginationNo = query.get("page");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
	let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;

	const loadApiData = async (offset) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/public-url-products`, {offset:offset, limit: config.pagination.limitPerPage}, token);
        if(apiData) {
            setListData(apiData.data.records);
            setTotalListCount(apiData.data.totalCount);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }
	
	const handlePageChange = async (pageNumber) => {
		utils.redirect(`/public-url-products?page=${pageNumber}`)
	}

	useEffect(() => {
        async function fetchData() {
			loadApiData((offset == 1) ? 0 : offset); // offset should start from 0
        };
        fetchData();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Public URL Products List</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Public Url Products', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/products" className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">CREATE URL</a>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col">
						{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <Table loadapidata={loadApiData} offset={offset} list={ListData} orgInfo={orgInfo} token={token} />}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default List;