// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// components 
import GalleryBox from '../../../components/gallery-box';
import ImagePreview from '../../../components/image-preview-box';
import specKeys from '../../../components/sepc-keys';
// config & functions 
import ajaxCall from '../../../utils/ajaxcall';
import config from "../../../config/index";

function FormModal({loadapidata, apidataparams, setshowformmodalbox, productid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        productId: "",
        groupName: "",
        name: "",
        image: "",
        rating: "",
        colorId: "",
        statusId: "",
        averageSellingPrice: "",
        description: "",
        descriptionPlaybook: "",
        specConfig: "",
        metaTag: ""
    });
    const disabledField = (orginfo.roleId === 1) ? false:true;
    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState((productid) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (productid) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [BrowseImage, setBrowseImage] = useState(false);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const [ColorList, setColorList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();
    const { fields:specList, append:appendSpecList, remove:removeSpecList } = useFieldArray({
        control,
        name: "items"
    });

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const handleProductImageName=((val)=>{ 
        handleStateConfig('image', val) 
    })

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const removeProductImage = (i) => {
        const imgArrList = (InputFields.image) ? InputFields.image.split(",") : null;
        if(imgArrList.length > 0) {
            imgArrList.splice(i,1);
        }
        handleStateConfig('image', imgArrList.join()) 
    }

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        let postData = {
            productId: productid,
            groupName: data.groupName,
            name: data.name,
            image: InputFields.image,
            rating: data.rating,
            colorId: data.colorId,
            statusId: data.statusId,
            averageSellingPrice: data.averageSellingPrice,
            description: data.description,
            specConfig: JSON.stringify(data.items),
            metaTag: data.metaTag
        }
        let apiCall = "";
        if(InputFields.productId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/products`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/products`, postData, token);
        }
        
        if(apiCall) {
            setSubmitButton((InputFields.productId)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton((InputFields.productId)?'UPDATE RECORD':'SAVE RECORD');
            toast.error('Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }
    
    const fetchColorList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/color`, {}, token);
        if(apiCall) {
            setColorList(apiCall.data);
        }
    }
   
    const fetchStatusList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/products/status`, {}, token);
        if(apiCall) {
            setStatusList(apiCall.data);
        }
    }

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orginfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (productid) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/products/row`, {
                    productId: (productid) ? productid : 0
                }, token);
                if(apiCall) {
                    let onLoadData = {
                        productId: productid,
                        groupName: apiCall.data[0].group_name,
                        name: apiCall.data[0].name,
                        image: apiCall.data[0].image,
                        rating: apiCall.data[0].rating,
                        colorId: apiCall.data[0].color_id,
                        statusId: apiCall.data[0].product_status_id,
                        averageSellingPrice: apiCall.data[0].average_selling_price,
                        description: apiCall.data[0].description,
                        descriptionPlaybook: apiCall.data[0].description_playbook,
                        specConfig: apiCall.data[0].specification_config && JSON.parse(apiCall.data[0].specification_config),
                        metaTag: apiCall.data[0].meta_tag
                    }
                    appendSpecList(apiCall.data[0].specification_config && JSON.parse(apiCall.data[0].specification_config))
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            } else {
                appendSpecList({ key: "", value: "" })
            }
        }
        fetchData();
        fetchColorList();
        fetchStatusList();
        loadComponent();
    }, []);

    const imgArrList = (InputFields.image) ? InputFields.image.split(",") : null;

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{productid?'Update Record':'Add Record'}</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            {(Notification.status) ?
                                                <div className="col-12 text-center">{Notification.message}</div> : <>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h3><b>Product Details</b></h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Name</label>
                                                        <input type="text" defaultValue={InputFields.name} className="form-control" name="name" {...register("name", { required: true, minLength: 5, maxLength: 125 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.name?.type === "required" && "Name is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.name?.type === "minLength" && "Enter atleast 5 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.name?.type === "maxLength" && "Only 125 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Group Name</label>
                                                        <input type="text" defaultValue={InputFields.groupName} className="form-control" name="groupName" {...register("groupName", { required: true, minLength: 8, maxLength: 12 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.groupName?.type === "required" && "Name is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.groupName?.type === "minLength" && "Enter atleast 8 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.groupName?.type === "maxLength" && "Only 12 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        {(orginfo.roleId === 1) ? <button type="button" className="btn btn-sm btn-primary" onClick={() => setBrowseImage(true)}>Browse Gallery</button> : null}
                                                        <div className="mt-2">
                                                            {(imgArrList && Array.isArray(imgArrList) && imgArrList.length > 0)? imgArrList.map((d,i) => {
                                                                return <span className="me-2 border border-2 rounded py-1 px-2" key={Math.random()}>{d} <FontAwesomeIcon icon={faSearch} onClick={e => handleViewImage(true, d)} className="dashboard-card-icon text-primay cursor-pointer" /> {(orginfo.roleId === 1) ? <FontAwesomeIcon icon={faTrash} onClick={e => removeProductImage(i)} className="dashboard-card-icon text-danger" /> : null}</span>
                                                            }):null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Rating</label>
                                                        <input type="text" defaultValue={InputFields.rating} className="form-control" name="rating" {...register("rating", { required: false, minLength: 1, maxLength: 3 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.rating?.type === "required" && "Rating is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.rating?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.rating?.type === "maxLength" && "Only 3 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Color</label>
                                                        <Controller
                                                            name='colorId' 
                                                            defaultValue={InputFields.colorId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="colorId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {(Array.isArray(ColorList) && ColorList.length > 0) ? ColorList.map(d => {
                                                                        return <option key={Math.random()} value={d.id}>{d.label}</option>
                                                                    }):null}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.colorId?.type === "required" && "Please update color"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Status</label>
                                                        <Controller
                                                            name='statusId' 
                                                            defaultValue={InputFields.statusId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="statusId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {(Array.isArray(StatusList) && StatusList.length > 0) ? StatusList.map(d => {
                                                                        return <option key={Math.random()} value={d.id}>{d.label}</option>
                                                                    }):null}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.statusId?.type === "required" && "Please update status"}
                                                        </small>
                                                    </div>
                                                    <div className={`${(orginfo.roleId === 1) ? `col-lg-5` : `col-lg-6`} mt-3`}>
                                                        <label className="form-label fs-14rem fw-bold">Average Selling Price</label>
                                                        <input type="text" defaultValue={InputFields.averageSellingPrice} className="form-control" name="averageSellingPrice" {...register("averageSellingPrice", { required: true, minLength: 2, maxLength: 5 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.averageSellingPrice?.type === "required" && "Average Selling Price is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.averageSellingPrice?.type === "minLength" && "Enter atleast 2 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.averageSellingPrice?.type === "maxLength" && "Only 5 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Description</label>
                                                        <textarea defaultValue={InputFields.description} className="form-control" name="description" {...register("description", { required: false, minLength: 5, maxLength: 500 })} disabled={disabledField}></textarea>
                                                        <small className="form-text text-danger">
                                                            {errors.description?.type === "required" && "Description is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.description?.type === "minLength" && "Enter atleast 5 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.description?.type === "maxLength" && "Only 500 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Description Playbook</label>
                                                        <textarea defaultValue={InputFields.descriptionPlaybook} className="form-control" name="descriptionPlaybook" disabled={true}></textarea>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Specification</label>
                                                    </div>
                                                    <div className="col-12">
                                                        {(specList && Array.isArray(specList) && specList.length > 0) ? 
                                                            specList.map((field, index) => {
                                                                return <div key={field.id} className="row border-bottom">
                                                                    <div className={`col-12 ${(orginfo.roleId === 1) ? `col-lg-5` : `col-lg-6`} mt-2 mb-2`}>
                                                                        <Controller
                                                                            name={`items[${index}].key`}
                                                                            defaultValue={field.key}
                                                                            rules={{required: true}}
                                                                            control={control} 
                                                                            render={({field}) => (
                                                                                <select value={field.value} className="form-control custom-select" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                                    <option value=""></option>
                                                                                    {specKeys && (Object.keys(specKeys).length > 0) ? Object.keys(specKeys).map((d,i) => {
                                                                                        return <option key={Math.random()} value={Object.keys(specKeys)[i]}>{specKeys[Object.keys(specKeys)[i]]}</option>
                                                                                    }):null}
                                                                                </select>
                                                                            )}
                                                                        />
                                                                        <small className="form-text text-danger">
                                                                            {errors.items && errors.items[index] && errors.items[index].key && errors.items[index]?.key.type === "required" && "Please update key"}
                                                                        </small>
                                                                    </div>
                                                                    <div className={`col-12 ${(orginfo.roleId === 1) ? `col-lg-5` : `col-lg-6`} mt-2 mb-2`}>
                                                                        <input type="text" {...register(`items[${index}].value`, { required: true })} defaultValue={field.value} className="form-control"  disabled={disabledField} />
                                                                        <small className="form-text text-danger">
                                                                            {errors.items && errors.items[index] && errors.items[index].value && errors.items[index]?.value.type === "required" && "Please update value"}
                                                                        </small>
                                                                    </div>
                                                                    {(orginfo.roleId === 1) ? <div className="col-12 col-lg-2 mt-2 mb-2 text-center d-flex justify-item-center align-items-start">
                                                                        {(specList.length > 1)?<button type="button" className="btn btn-sm border-danger text-danger me-2" onClick={() => removeSpecList(index)}>-</button>:null}
                                                                        <button type="button" className="btn btn-sm border-dark text-dark" onClick={() => appendSpecList({ key: "", value: "" })}>+</button>
                                                                    </div> : null}
                                                                </div>
                                                            })
                                                        :null}
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Meta Tag Config</label>
                                                        <textarea defaultValue={InputFields.metaTag} rows="5" className="form-control" name="metaTag" {...register("metaTag", { required: true, minLength: 5, maxLength: 800 })} disabled={disabledField}></textarea>
                                                        <small className="form-text text-danger">
                                                            {errors.metaTag?.type === "required" && "Meta tags is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.metaTag?.type === "minLength" && "Enter atleast 5 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.metaTag?.type === "maxLength" && "Only 800 characters allowed"}
                                                        </small>
                                                    </div>
                                                    {(orginfo.roleId === 1) ? <div className="col-12 text-end">
                                                        <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                    </div> : null }
                                                </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(BrowseImage)? <GalleryBox orgInfo={orginfo} handlegalleryimagenamefn={handleProductImageName} setbrowseimage={setBrowseImage} selectedimg={InputFields.image} token={token} action="selectable" />:null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    )
}

export default FormModal;