import React from 'react'
import Logo from '../../components/logo';
import '../../assets/css/core.css';
import Footer from '../../components/footer';

function ForgotPassword() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="login-form bg-light mt-4 p-4">
                            <form action="" method="" className="row g-3">
                                <div className="text-center mb-3 mt-4">
                                    <Logo />
                                </div>
                                <div>
                                    <h4>Forgot Your Password ?</h4>
                                    <p>Enter your email address and we will send you instructions to reset your password</p>
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="mb-2">Email address or Number</label>
                                    <input type="text" name="username" className="form-control" placeholder="Enter your email" />
                                </div>
                                <div className="col-12 d-grid gap-2 col-6 mx-auto">
                                    <button type="submit" className="vt-primary-btn btn-sm btn btn-outline-none pb-2">Reset password</button>
                                </div>
                            </form>
                            <div className="col-12 text-center py-2">
                                <a href="/login">Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-bottom"><Footer /></div>
        </div>
    )
}

export default ForgotPassword
