import { useState } from "react";

export default function UserToken() {

    const saveToken = (userToken) => {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    }

    const [token, setToken] = useState(localStorage.getItem('token'));

    return {
        setToken: saveToken,
        token
    }
}