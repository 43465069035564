import axios from "axios";
import utils from ".";

const getCall = async (url, paramData='', token='', addOnHeader={}) => {
    let tokenHeader = {
        "X-Token": `${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    let resp = await axios.get(url, {
        params: paramData,
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return res;
    })
    .catch((err) => {
        if (err.response) {
            if(err.response.status === 401) {
                utils.logout();
                window.location.href = "/login";
            } else {
                return false;
            }
        } else {
            return false;
        }
    })

    return resp;
}

const postCall = async (url, paramData='', token='', addOnHeader={}) => {
    let tokenHeader = {
        "X-Token": `${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json"
    }

    let resp = await axios.post(url, paramData, {
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return res;
    })
    .catch((err) => {
        if (err.response) {
            if(err.response.status === 401) {
                utils.logout();
                window.location.href = "/login";
            } else {
                return false;
            }
        } else {
            return false;
        }
    })

    return resp;
}

const patchCall = async (url, paramData='', token='', addOnHeader={}) => {
    let tokenHeader = {
        "X-Token": `${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    let resp = await axios.patch(url, paramData, {
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return res;
    })
    .catch((err) => {
        if (err.response) {
            if(err.response.status === 401) {
                utils.logout();
                window.location.href = "/login";
            } else {
                return false;
            }
        } else {
            return false;
        }
    })

    return resp;
}

const deleteCall = async (url, paramData='', token='', addOnHeader={}) => {
    let tokenHeader = {
        "X-Token": `${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    let resp = await axios.delete(url, {
        params: paramData,
        headers: Object.assign(tokenHeader, addOnHeader)
    })
    .then((res) => {
        return res;
    })
    .catch((err) => {
        if (err.response) {
            if(err.response.status === 401) {
                utils.logout();
                window.location.href = "/login";
            } else {
                return false;
            }
        } else {
            return false;
        }
    })

    return resp;
}

export default {
    getCall, 
    postCall,
    patchCall,
    deleteCall
}