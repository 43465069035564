import React from 'react'

function Logo() {
    return (
        <div>
            <a href="/" className="navbar-brand"> <img src="/assets/images/vt-h.png" alt="" /></a>
        </div>
    )
}
export default Logo;
