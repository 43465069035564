import React, { useState } from 'react';
// import '../assets/css/core.css';
import Logo from './logo';
import utils from '../utils';

function Navbar({orgInfo}) {

    const [SourceDropdown, setSourceDropdown] = useState(false);
    const [UserDropdown, setUserDropdown] = useState(false);

    const handleLogout = () => {
        utils.removeLocalStorage('token');
        utils.removeLocalStorage('PublicUrlIds');
        window.location.href = "/login";
    }

    return (
        <div className="navbar navbar-expand-lg shadow-sm p-2 bg-body rounded sticky-top border-bottom">
            <div className="container">
                <Logo />
                <button type="button" className="navbar-toggler bg-light" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end " id="navbarCollapse">
                    <div className="navbar-nav ml-auto ">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a href="/dashboard" className="nav-link vt-txt-dark-blue fw-bold">DASHBOARD</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle vt-txt-dark-blue fw-bold" href="#" onClick={(e) => setSourceDropdown(!SourceDropdown)} id="sourceDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">SOURCE</a>
                                <div className={`dropdown-menu ${(SourceDropdown)?` show`:``}`} aria-labelledby="sourceDropdown">
                                    {(orgInfo.roleId === 1) ? <>
                                        <a href="/products" className="dropdown-item">Products</a>
                                        <a href="/winsight" className="dropdown-item">Winsight</a>
                                        <a href="/public-url-products" className="dropdown-item">Public URL's</a>
                                        <a href="/uploads" className="dropdown-item">Uploads</a>
                                    </>:<>
                                        <a href="/winsight" className="dropdown-item">Winsight</a>
                                    </>}
                                </div>
                            </li> 
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle vt-txt-dark-blue fw-bold" href="#" onClick={(e) => setUserDropdown(!UserDropdown)} id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Welcome {orgInfo.user || 'User'}</a>
                                <div className={`me-auto dropdown-menu ${(UserDropdown)?` show`:``}`} aria-labelledby="userDropdown">
                                    <button className="dropdown-item" href="#" onClick={() => handleLogout()}>Logout</button>
                                </div>
                            </li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
